body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-container.ui.container {
  margin-left: 0!important;
  margin-right: 0!important;
}

.m-top {
  margin-top: 2em;
}

.ui.segment.logo-header {
  padding: 0.2em 1em;
}

.ui.floated.header.logo-header-text {
  margin-top: 0.7em;
}

.ui.segments, .ui.segment {
  border: none !important;
}

.req {
  color: red;
  font-size: 1.1em;
  padding-left: 0.1em;
}

.custom-help {
  display: block;
  text-align: center;
  font-size: 0.85rem;
  color: #b9b9b9;
  font-style: italic;
}

.error.field {
  margin-bottom: 0 !important;
}

.custom-error {
  text-align: left;
  font-size: 0.85rem;
  color: #df2525;
  font-style: italic;
}

.label-bold {
  font-weight: bold;
  display: block;
  margin-bottom: 0.3em;
}

.fixed-table {
  overflow: auto;
  max-height: 25em;
}

.exceed-max-err-alert {
  visibility: hidden;
  position: absolute;
  left: 50%;
}

.table-input {
  min-width: 10em;
}

.table-input input {
  padding: 0.45em !important;
}

.modally {
  cursor: pointer;
}

.custom_toast {
  width: 95% !important;
}

.table_content_div p {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table_content_div p > span {
  color: #888;
  padding-left: 4px;
}

.label-balance {
  font-weight: bold;
  display: block;
  margin-bottom: 0.3em;
  float: right;
}

@media(max-width: 576px) {
  .ui.very.padded.segment {
    padding: 1em;
  }

  .ui.table:not(.unstackable) tr {
    padding-top: 0.2em;
    padding-bottom: 0.4em;
  }
}

@media(max-width: 766px) {
  .table_content_div {
    max-width: 70vw;
  }
}

@media(min-width: 767px) {
  .table_content_div {
    max-width: 30vw;
  }
}

